<template>
  <div class="slider-standart__arrows" :class="{'slider-standart__arrows--no-hide': noHide}">
    <button
      class="slider-standart__arrow slider-standart__arrow--prev"
      type="button"
      :disabled="isBeginning && !loop"
      @click="swiper?.slidePrev()"
    >
      <left-arrow-small-svg></left-arrow-small-svg>
    </button>
    <button
      class="slider-standart__arrow slider-standart__arrow--next"
      type="button"
      :disabled="isEnd && !loop"
      @click="swiper?.slideNext()"
    >
      <left-arrow-small-svg></left-arrow-small-svg>
    </button>
  </div>
</template>

<script setup lang="ts">
import {type Swiper as SwiperClass} from 'swiper';
import {type PropType, watch} from '#imports';
import {computedWritable} from '@/composables/useWritableComputed';
import LeftArrowSmallSvg from 'svg/arrow.vue';

const props = defineProps({
  swiper: {type: Object as PropType<SwiperClass>, required: false},
  loop: {type: Boolean, required: false, default: false},
  noHide: {type: Boolean, required: false},
});

const isBeginning = computedWritable(() => props.swiper?.isBeginning || false);
const isEnd = computedWritable(() => props.swiper?.isEnd || false);

watch(
  () => props.swiper,
  (swiper) => {
    // When swiper is created and the current slide is the first one, `isBeginning` is still set to `false` for some reason
    swiper?.on('reachBeginning', () => {
      isBeginning.value = true;
    });
    swiper?.on('reachEnd', () => {
      isEnd.value = true;
    });
  },
  {immediate: true},
);
</script>
